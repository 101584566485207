$(document).ready(function () {
    
    $('body').on('click', '.trigger-mobile-menu', function () {
        if ($('.top-nav').hasClass('active')) {
            $('.top-nav').removeClass('active');
        } else {
            $('.top-nav').addClass('active');
        }
    });

    $('body').on('click', '.nav-close-trigger', function () {
        $('.top-nav').removeClass('active');
    });

    $('body').on('click', '.scrollto', function () {
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top
        }, 2000);
    });

    $('body').on('click', '.tab-nav > a', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $target = $($this.attr('href'));
        if ($target.length) {
            $('.tab-nav > a').removeClass('active');
            $this.addClass('active');

            $('.tab-block').removeClass('active');
            $target.addClass('active');
        }
    });
}); 
